<template>
  <div id="amap" class="basemap">
    <div class="timeline">
        <Carousel :value="carouselpoints" :numVisible="3" :numScroll="1" :page="carouselpage"  ref="carousel">
            <template #item="slotProps">
                <div class="timeline-item" :id="slotProps.data.id" @click="toggleMarker(slotProps.data.id)" style="font-size: large;">
                     <div>{{slotProps.data.title}}</div>
                     <!-- {{slotProps.data.date}}
                     {{slotProps.data.keywords}}
                     {{slotProps.data.description}} -->
                     <img :src="`data:image/png;base64,${slotProps.data.recordthumb}`" style="height: 124px; width: 240px; object-fit: cover;"/>
                </div>
            </template>
        </Carousel>
    </div>
    <OverlayPanel ref="addLifePanel" appendTo="body" id="addLifePanel" class="lifePanel" :dismissable="false">
        <div v-if="$store.state.authenticated">
            <Dropdown v-model="selectedLifeLineId" :options="lifelines" optionLabel="title" optionValue="id" placeholder="Selecteer een levenslijn" style="margin-top: 5px; width: 100%"  @change="lifeLineSelected"/>
        </div>
        <div id="lineform" style="margin-top: 5px;">
            <div class="field">
                <label for="txttitle"><b>Titel</b></label>
                <InputText type="text" v-model="newLifeLine.title" id="txttitle" placeholder="Geef het een naam"  />
            </div>
            <div class="field">
                <label for="txtauthor"><b>Auteur</b></label>
                <InputText type="text" v-model="newLifeLine.author" id="txtauthor" placeholder="Geef uw pseudoniem" />
            </div>
            <div class="field">
                <label for="cPicker"><b>Lijnkleur</b></label><br />
                <ColorPicker v-model="newLifeLine.color" id="cPicker" />
            </div>
            <div>
                <label for="rLine"><b>Lijnstijl</b></label><br />
                <div class="field-radiobutton">
                    <RadioButton name="style" value="0" v-model="newLifeLine.style" id="rLine1" /><label for="rLine1" style="padding:5px;">doorgetrokken lijn</label>
                    <RadioButton name="style" value="2" v-model="newLifeLine.style" id="rLine2" /><label for="rLine2" style="padding:5px;">stippellijn</label>
                </div>
            </div>
            <div class="field">
                <label for="dt"><b>Levenspunten</b></label><br />
                <DataTable ref="dt" :value="newLifeLine.lifePoints" class="p-datatable-sm" :rowClass="rowClass">
                    <Column header="Afbeelding" style="max-width: 50px;">
                        <template #body="{data}">
                            <img v-if="data.category==1" width="15" height="15" class="center" :src="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIUlEQVQ4T2N8K6Pyn4FMwASlyQKjmkkEo5pJBENSMwMDAPdQAkpCB3VDAAAAAElFTkSuQmCC'"/>
                            <img v-if="data.category==2" width="50" height="50" class="center" style="width: 50px; height: 50px; object-fit: cover;" :src="`data:image/png;base64,${data.pointthumb}`"/>
                            <img v-if="data.category==3" width="26" height="16" class="center" :src="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAALtJREFUSInN1rEVwjAMRdEPXiB9ChXZgdKTpWQyl2zhETIBVARyLMuSI3N4vf5tBfxrMdIaI63Wu2BFgOcNwEw0LTlvyR36Qt6ZMBXEIGasCQmICRMhBaLGqpABUWEs1IE0sQI6gYjYAXJAqtgOOSIsFgYhBXYdMM4WACDnLRFNC4DZd/7ySCnfd2gM9kEOkC92RArIBysRFjqH8UgV6sPqiAjZMBlpQjqsjaggGdMhaojH9EhXve/Wz3oBn7KW/yvia4IAAAAASUVORK5CYII='"/>
                        </template>
                    </Column>
                    <Column field="title" header="Titel" style="max-width: 190px;"></Column>
                    <Column field="date" header="Datum" ></Column>
                    <Column :exportable="false">
                        <template #body="slotProps">
                            <i class="pi pi-pencil" @click="editPoint(slotProps.data,$event)"></i><br/>
                            <i class="pi pi-trash" @click="deletePoint(slotProps.data,$event)"></i>
                            <!-- <Button icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="editPoint(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-text"  @click="deletePoint(slotProps.data)" /> -->
                        </template>
                    </Column>
                </DataTable>
                <div style="display:flex; justify-content: flex-end; margin-top: 5px;">
                    <Button label="Levenspunt toevoegen" class="p-button-outlined" v-if="newLifeLine.lifePoints && newLifeLine.lifePoints.length < 7" @Click="openAddPoint" ></Button>
                </div>
                <div style="position: absolute;  bottom: 10px;  right: 16px;">
                    <div style="display: flex; justify-content: space-between; width: 550px;">
                        <Button label="Annuleren" class="p-button-outlined" style="flex: 0 0 50" @Click="cancelLine"></Button>
                        <!-- <Button label="Preview" class="p-button-outlined" style="flex: 0 0 50" @Click="previewLifeLine"></Button> -->
                        <Button label="Verwijderen" class="p-button-outlined" style="flex: 0 0 50" @Click="dropLine" v-if="$store.state.authenticated"></Button>
                        <Button label="Publiceren" class="p-button-outlined" style="flex: 0 0 50" @Click="saveLine"></Button>
                   </div>
                   <div v-if="newLifeLine.isPublic">
                        <Checkbox id="enabled" v-model="newLifeLine.isEnabled" :binary="true" />
                        <label for="enabled" style="margin-left: 5px;">This public lifeline is enabled</label>
                    </div>
                    <div v-else>
                        This is a iwi lifeline.
                    </div>
                </div>
            </div>
        </div>        
    </OverlayPanel>
    <OverlayPanel ref="pointPanel" appendTo="body" id="pointPanel" class="pointPanel"  :dismissable="false">
        <div>Voer gegevens levenspunt in</div>
        <div id="lineform" style="margin-top: 5px;">
            <div class="field">
                <label for="txttitle"><b>Titel</b></label>
                <InputText type="text" v-model="selectedLifePoint.title" id="txttitle" placeholder="Geef het een naam"  />
            </div>
            <div class="field">
                <label for="txtdate"><b>Datum</b></label>
                <!-- <InputMask v-model="selectedLifePoint.date" mask="9999/99/99" id="txtdate" slotChar="yyyy/mm/dd" /> -->
                <InputText type="text" v-model="selectedLifePoint.date" id="txtdate" placeholder="Periode, jaar of datum"  />
            </div>
            <div class="field">
                <label for="txtkeywords"><b>Trefwoorden</b></label>
                <InputText type="text" v-model="selectedLifePoint.keywords" id="txtkeywords" placeholder="Voer relevante zoekwoorden in"  />
            </div>
            <div class="field">
                <label for="txtshortdescription"><b>Korte beschrijving</b></label>
                <Textarea v-model="selectedLifePoint.shortdescription" id="txtshortdescription"  placeholder="Voer een korte beschrijving in" rows="3" style="resize: none;" />
            </div>
            <div class="field">
                <label for="txtdescription"><b>Beschrijving</b></label>
                <div style="display: flex; justify-content: space-between; width: 550px;">
                    <InputText v-model="selectedLifePoint.description" id="txtdescription"  placeholder="Voer een volledige beschrijving in, Klik op de pijl om uit te vouwen" />
                    <i class="pi pi-arrow-circle-right" style="flex: 0 0 50; margin-left: 10px; padding-top: 12px;" @click="displayPointPanel=true"></i>
                </div>
                <Dialog v-model:visible="displayPointPanel"  :maximizable="true" :modal="true">
                    <QuillEditor ref="editor" id="editor" theme="snow" :toolbar="toolbar"  :modules="modules" contentType="html" v-model:content="selectedLifePoint.description" />
                </Dialog>
            </div>
            <div class="field">
                <label for="txtcoordinates"><b>Coördinaten</b></label>
                    <div style="display: flex; justify-content: space-between; width: 550px;">
                        <InputText type="text" v-model="selectedLifePoint.coordinates" id="txtcoordinates" placeholder="Selecteer coördinaten op de kaart" style="flex: 0 0 50"  />
                        <i class="pi pi-arrow-circle-right" style="flex: 0 0 50; margin-left: 10px; padding-top: 12px;" @click="selectPoint"></i>
                        <i class="pi pi-save" style="flex: 0 0 50; margin-left: 10px; padding-top: 12px;" @click="saveSelectPoint"></i>
                    </div>
            </div>
            <div class="field">
                <label for="ddcategories"><b>Categorie</b></label><br />
                <Dropdown v-model="selectedLifePoint.category" optionValue="id" optionLabel="name" :options="categories" placeholder="Selecteer een categorie" style="margin-top: 5px; width: 100%"  @change="categorySelected"></Dropdown>
            </div>
            <div class="field">
                <div style="width: 150px; float: left;">
                    <label for="uplimage"><b>Afbeelding opnemen</b></label><br />
                    <img :src="`data:image/png;base64,${selectedLifePoint.recordthumb}`" style="width: 150px; height: 150px; object-fit: contain;" />
                    <FileUpload ref="uprecord" mode="basic" name="mediaFile" :multiple="false" @upload="onUploadRecord"  :url="`${$config.iwib.server}/records/publicupload_record`" accept="image/*" :fileLimit="1" @before-send="prepareUpload" :maxFileSize="1000000"></FileUpload>
                </div>
                <div style="width: 150px; float: right;" v-if="selectedLifePoint.category==2">
                    <label for="uplimage"><b>Afbeelding voor punt</b></label><br />
                    <img :src="`data:image/png;base64,${selectedLifePoint.pointthumb}`" style="width: 150px; height: 150px; object-fit: contain;" />
                    <FileUpload ref="uppoint" mode="basic" name="mediaFile" :multiple="false" @upload="onUploadPoint"  :url="`${$config.iwib.server}/records/publicupload_point`" accept="image/*" :fileLimit="1" @before-send="prepareUpload" :maxFileSize="1000000"></FileUpload>
                </div>
            </div>
            <div class="field">
                <div style="position: absolute;  bottom: 20px;">
                    <div style="display: flex; justify-content: space-between; width: 550px;">
                        <Button label="Annuleren" class="p-button-outlined" style="flex: 0 0 50" @Click="cancelPoint"></Button>
                        <Button label="Opslaan" class="p-button-outlined" style="flex: 0 0 50" @Click="savePoint"></Button>
                    </div>
                </div>
            </div>
        </div>      
    <!-- </Dialog>   -->
    </OverlayPanel>
    <ConfirmPopup></ConfirmPopup>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'
import lifeService from '../services/life_service'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import config from '../../config'
//import recordVue from "./record"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import ConfirmPopup from 'primevue/confirmpopup'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  components: {
    //recordVue,
    ConfirmPopup,
    QuillEditor
  },
  setup: () => {
    const modules = {
      name: 'blotFormatter',  
      module: BlotFormatter, 
      options: {/* options */}
    }
    return { modules }
  },
   watch: {
    $route: "reload",
  },

    data () {
        return {
            map: null,
            selectMarker: null,
            route:  [],
            //currentPath: this.$router.currentRoute.value.params.recordTitle,
            center: [ -23.0, 15.0 ],
            zoom: 2,
            carouselpoints: [],
            carouselcurrentlineid: -1,
            carouselpage: 0,
            markers: {},
            lastMarker: -1,
            lifelines: [{title: 'Maak een nieuwe levenslijn', id: -1}],
            selectedLifeLineId: undefined,
            selectedLifeLine: {title: null, author: null, color: '1976D2', id: -1},
            lifepoints: [],
            selectedLifePointId: undefined,
            selectedLifePoint: {title: null, keywords: null, date: null, id: -1, coordinates: null, pointthumb: null, recordthumb: null, shortdescription: null, description: null, category: 1},
            categories: [{id: 1, name: 'Publicatie'},{id: 2, name: 'Publiek'},{id: 3, name: 'Biografische gebeurtenis'}],
            newLifeLine: {title: null, author: null, color: '1976D2', id: -1, lifePoints: [], style: 0, isEnabled: true, isPublic: false, isTjalie: true},
            isEditPoint: 0,
            isEditLine: 0,
            paramsdone: false,
            toolbar: [      [{ header: [1, 2, false] }],      ['bold', 'italic', 'underline'],      ['image', 'code-block']    ],
            displayPointPanel: false
        }
    },
    lifeservice: null,
    created() {
        this.lifeservice = new lifeService();
        this.getLines()
    },
    mounted() {
        if (window.localStorage.getItem('P_CENTER')) {
            var c = JSON.parse(window.localStorage.getItem('P_CENTER'))
            this.center = [c.lng, c.lat]
        }
        if (window.localStorage.getItem('P_ZOOM')) {
            this.zoom = window.localStorage.getItem('P_ZOOM')
        }
        this.displayMap(this.center, this.zoom)
        // if (this.$route.params && this.$route.params.line && this.$route.params.point) {
        //     this.getCarousel(this.$route.params.line, 1, {cancelable: false})
        //     this.toggleMarker(this.$route.params.point)
        // }
    },
    updated() {
        // if (!this.$router.currentRoute.value.params.recordTitle) this.displayMap(this.center) //later save click location to come back to it
    },
    methods: {
        deleteLine() {
            if (this.newLifeLine.id > 0 && this.lifeservice.deleteLine(this.newLifeLine.id)) {
                        this.$confirm.require({
                            target: event.currentTarget,
                            acceptLabel: 'Ok',
                            rejectClass: 'displayNone',
                            message: 'Uw levenslijn is succesvol geverwijverd!',
                            icon: 'pi pi-exclamation-circle',
                            accept: () => {
                                console.log('ok')
                                this.$refs.addLifePanel.hide()
                                this.lifelines =  [{title: 'Maak een nieuwe levenslijn', id: -1}]
                                this.getLines()
                            },
                        })
                    }
        },
        saveLine () {
            if (this.newLifeLine.title && this.newLifeLine.author && this.newLifeLine.lifePoints && this.newLifeLine.lifePoints.length > 0) {
                if (this.isEditLine == 0) {
                    // new line
                    if (this.lifeservice.createLineWithPoints(this.newLifeLine)) {
                        this.$confirm.require({
                            target: event.currentTarget,
                            acceptLabel: 'Ok',
                            rejectClass: 'displayNone',
                            message: 'Dank u! Uw levenslijn wordt binnenkort beoordeeld!',
                            icon: 'pi pi-exclamation-circle',
                            accept: () => {
                                console.log('ok')
                                this.$refs.addLifePanel.hide()
                            },
                        })
                    }
                } else {
                    //update old line
                    if (this.lifeservice.updateLineWithPoints(this.newLifeLine)) {
                        this.$confirm.require({
                            target: event.currentTarget,
                            acceptLabel: 'Ok',
                            rejectClass: 'displayNone',
                            message: 'Dank u! Uw levenslijn is succesvol geüpdatet!',
                            icon: 'pi pi-exclamation-circle',
                            accept: () => {
                                console.log('ok')
                                this.$refs.addLifePanel.hide()
                            },
                        })
                    }
                }
            } else {
                this.$confirm.require({
                    target: event.currentTarget,
                    acceptLabel: 'Ok',
                    rejectClass: 'displayNone',
                    message: 'Alle velden zijn verplicht',
                    icon: 'pi pi-exclamation-triangle',
                })
            }
        },
        savePoint() {
            if (this.selectedLifePoint.title && this.selectedLifePoint.date && this.selectedLifePoint.description && this.selectedLifePoint.coordinates ) {
                if (this.isEditPoint == 0) {
                    this.newLifeLine.lifePoints.push(this.selectedLifePoint)
                } else {
                    const toChange = this.newLifeLine.lifePoints.findIndex(
                        (p) => p.id === this.editPoint
                    )
                    this.newLifeLine.lifePoints[toChange] = this.selectedLifePoint
                }
                this.$refs.pointPanel.hide()
            } else {
                this.$confirm.require({
                    target: event.currentTarget,
                    acceptLabel: 'Ok',
                    rejectClass: 'displayNone',
                    message: 'Alle velden zijn verplicht',
                    icon: 'pi pi-exclamation-triangle',
                })
            }
        },
        editPoint(args, e) {
            this.isEditPoint = args.id
            this.selectedLifePoint = args
            this.$refs.pointPanel.toggle(e)
        },
        async onUploadPoint(event) {
            const up = await fetch(`${config.images.server}/images/${event.files[0].name}?width=150&height=150&fit=contain&background=ffffff`, {method: 'GET'})

            let string = '';
            let buf = await up.arrayBuffer()
            new Uint8Array(buf).forEach(
                (byte) => { string += String.fromCharCode(byte) }
                )              
            this.selectedLifePoint.pointthumb = btoa(string)
        },
        async onUploadRecord(event) {
            const up = await fetch(`${config.images.server}/images/${event.files[0].name}?width=150&height=150&fit=contain&background=ffffff`, {method: 'GET'})

            let string = '';
            let buf = await up.arrayBuffer()
            new Uint8Array(buf).forEach(
                (byte) => { string += String.fromCharCode(byte) }
                )              
            this.selectedLifePoint.recordthumb = btoa(string)
            this.selectedLifePoint.recordmedia = event.files[0].name
        },
        prepareUpload(event) {
            let user = JSON.parse(localStorage.getItem("user"))
            //event.xhr.open('POST', 'http://localhost:4000/records/upload', true);
            //event.xhr.withCredentials = true;
            event.xhr.setRequestHeader("Authorization", "Bearer " + user.token)
        },
        categorySelected(){

        },
        saveSelectPoint() {
            this.selectMarker.remove()
        },
        selectPoint() {
            this.selectMarker = new mapboxgl.Marker({
                draggable: true,
            })
                .setLngLat(
                    this.selectedLifePoint.coordinates == null
                        ? this.map.getCenter()
                        : JSON.parse(this.selectedLifePoint.coordinates)
                )
                .on('dragend', () => {
                    var lngLat =  this.selectMarker.getLngLat()
                    this.selectedLifePoint.coordinates = JSON.stringify([lngLat.lng, lngLat.lat])
                })
                .addTo(this.map)
        },
        cancelPoint(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'U verliest al het niet-opgeslagen werk. Weet u het zeker ?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Ja',
                rejectLabel: 'Nee',
                accept: () => {
                    this.$refs.pointPanel.hide()
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },
        cancelLine(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'U verliest al het niet-opgeslagen werk. Weet u het zeker ?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Ja',
                rejectLabel: 'Nee',
                accept: () => {
                    this.$refs.addLifePanel.hide()
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },
        dropLine(event) {
            if (this.newLifeLine.id > 0) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: 'U verliest al het niet-opgeslagen werk. Weet u het zeker ?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Ja',
                    rejectLabel: 'Nee',
                    accept: () => {
                        this.deleteLine()
                        this.$refs.addLifePanel.hide()
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                })
            }
        },
        openAddPoint(e) {
            this.isEditPoint = 0
            this.selectedLifePoint = {title: null, keywords: null, date: null, id: Math.floor(Math.random() * 100001), coordinates: null, pointthumb: null, recordthumb: null, shortdescription: null, description: null, category: 1}
            this.$refs.pointPanel.toggle(e)
        },
        deletePoint(args, event) {
            if (args) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: 'Weet u het levenslijn dat u dit levenspunt wilt verwijderen?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Ja',
                    rejectLabel: 'Nee',
                    accept: () => {
                        // console.log(args) 
                        // this.lifeservice.deletePublicPoint(args.id).then(data => {
                        //     this.lifepoints = data
                        // })                    
                        this.newLifeLine.lifePoints = this.newLifeLine.lifePoints.filter(function(el) { return el.title != args.title}); 
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            }
        },
        rowClass () {
            return 'small-row'
        },
        lifeLineSelected(e) {
            if (e.value==-1) {
                this.isEditLine = 0
            } else {
                this.lifeservice.getLine(e.value).then(data => {
                    this.newLifeLine = data
                })
                this.lifeservice.getPoints(e.value).then(data => {
                    this.newLifeLine.lifePoints = data
                })
                this.isEditLine = this.newLifeLine.id
            }
        },
        reload() {
            //this.currentPath = this.$router.currentRoute.value.params.recordTitle
        },
        getLines() {
            this.lifeservice.getTjalie().then(data => {
                this.lifelines = this.lifelines.concat(data)
            })
        },
        toggleMarker(markerId) {
            if (this.lastMarker > -1) this.markers[this.lastMarker].togglePopup()
            const mark = this.markers[markerId]
            this.map.flyTo({center: mark.getLngLat(), zoom: 5, speed: 0.9, curve: 1, easing(t) { return t;}})
            mark.togglePopup()
            this.lastMarker = markerId
        },
        getCarousel(lineId, index, e) {
            if (lineId != this.carouselcurrentlineid) {
                this.lifeservice.getPoints(lineId).then(data => {
                    this.carouselpoints = data
                    this.carouselcurrentlineid = lineId
                    this.carouselpage = index
                    this.$refs.carousel.navBackward(e,index)
                })
            } else {
                this.carouselpage = index
                this.$refs.carousel.navBackward(e,index)
            }
        },
        previewLifeLine() {
            let routeSourcePoints =[]
            let vm = this
            this.newLifeLine.lifePoints.forEach(function (marker) {
                routeSourcePoints.push(JSON.parse(marker.coordinates))
                // create a DOM element for the marker
                var el = document.createElement('div');
                // el.addEventListener('click', function(e){
                //     // Prevent the `map.on('click')` from being triggered
                //     //e.stopPropagation();
                //     lineLayers.forEach(layer => vm.map.setLayoutProperty(layer, 'visibility', 'none'))
                //     vm.map.setLayoutProperty(line.title, 'visibility', 'visible')
                //     vm.getCarousel(line.id, index, e)
                //     vm.lastMarker = marker.id
                // });                           
                if (marker.category==1) {
                    //publicaties
                    el.className = 'publicaties';
                    el.style.backgroundImage = 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIUlEQVQ4T2N8K6Pyn4FMwASlyQKjmkkEo5pJBENSMwMDAPdQAkpCB3VDAAAAAElFTkSuQmCC")';
                    el.style.width = '15px'; 
                    el.style.height = '15px'; 
                } else if (marker.category==2) {
                    //gebeurtenis
                    el.className = 'gebeurtenis';
                    el.style.backgroundImage = `url("data:image/png;base64,${marker.thumb}")`;
                    el.style.backgroundSize = '60px 60px';
                    el.style.width = '60px'; //marker.properties.iconSize[0] + 'px';
                    el.style.height = '60px'; //marker.properties.iconSize[1] + 'px';
                } else {
                    //publiek
                    el.className = 'publiek';
                    el.style.backgroundImage = 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAALtJREFUSInN1rEVwjAMRdEPXiB9ChXZgdKTpWQyl2zhETIBVARyLMuSI3N4vf5tBfxrMdIaI63Wu2BFgOcNwEw0LTlvyR36Qt6ZMBXEIGasCQmICRMhBaLGqpABUWEs1IE0sQI6gYjYAXJAqtgOOSIsFgYhBXYdMM4WACDnLRFNC4DZd/7ySCnfd2gM9kEOkC92RArIBysRFjqH8UgV6sPqiAjZMBlpQjqsjaggGdMhaojH9EhXve/Wz3oBn7KW/yvia4IAAAAASUVORK5CYII=")';
                    el.style.width = '26px';
                    el.style.height = '26px';
                }
    
                // add marker to map
                var m = new mapboxgl.Marker({draggable: false, element: el});
                    m.setLngLat(JSON.parse(marker.coordinates))
                    if (marker.title) m.setPopup(new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(
                        `<div style="background-color: #67a178; height: 200px; width: 130px;"><div><img ` +
                        `src="data:image/png;base64,${marker.thumb}" width="130" height="100"` +
                        `/></div><div style="padding: 5px;"><h1 style="color: black"><a href="/tjalie/${marker.id}" target="_self">${marker.title}</a></h1></div></div>`))
                    m.addTo(vm.map);
                //this.markers[marker.id] = m
            })
            this.map.addSource(this.newLifeLine.title, {
                'type':'geojson', 
                'data': {
                    'type': 'FeatureCollection',
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'properties': {},                   
                            'coordinates': routeSourcePoints
                        }
                    }]
                }
            })
            this.map.addLayer({
                'id': this.newLifeLine.title,
                'source': this.newLifeLine.title,
                'layout': {
                    'visibility': 'none'
                },
                'type': 'line',
                'visibility': 'none',
                'paint': {
                    'line-width': 2,
                    'line-color': '#' + this.newLifeLine.color ?? '#007cbf',
                    'line-dasharray': [2,Number(this.newLifeLine.style)] //line.style === 'dash' ? [2,3] : [2,0]
                }
            })
        },
        displayMap(center, zoom) {
            var vm = this;
            //load map
            const nav = new mapboxgl.NavigationControl();
            mapboxgl.accessToken = config.mapbox.token; //'pk.eyJ1IjoiamhmZWxlY3RyaWMiLCJhIjoiY2s5Y2w1aWxpMDN1cTNscjNhbnVyeXV3dyJ9.aNc2JuskzFcD_fjSkPwyIw';
            this.map = new mapboxgl.Map({
                container: 'amap',
                style: config.mapbox.style, //'mapbox://styles/jhfelectric/ckkdhuvp50b4817my44r9ohmu',
                center: center,
                zoom: zoom,
                minZoom: 2
            });
            //this.map.setLayoutProperty('country-label', 'text-field', ['get', 'name_fr'])
            //add controls
            this.map.addControl(nav, "top-right");
            this.geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
            })
            this.geocoder.on("result", function(e) {
                vm.marker.setLngLat(e.result.center)
            })
            this.map.addControl(this.geocoder)
            if (this.$store.state.authenticated) {
                const hello = new HelloWorldControl(this)
                this.map.addControl(hello, "top-left");
            }
            //add markers
            var routeSourcePoints= []
            var lineLayers = []
            this.lifeservice.getTjalie().then(lines => {
                lines.forEach(function (line) {
                    line.layered = false
                    let rnd = "_" + Math.floor((Math.random() * 10000)).toString()
                    lineLayers.push(line.title+rnd)
                    vm.lifeservice.getPoints(line.id).then(data => {
                        routeSourcePoints =[]
                        data.forEach(function (marker, index) {
                            routeSourcePoints.push(JSON.parse(marker.coordinates))
                            // create a DOM element for the marker
                            var el = document.createElement('div');
                            el.addEventListener('click', function(e){
                                // Prevent the `map.on('click')` from being triggered
                                //e.stopPropagation();
                                lineLayers.forEach(layer => vm.map.setLayoutProperty(layer, 'visibility', 'none'))
                                vm.map.setLayoutProperty(line.title+rnd, 'visibility', 'visible')
                                vm.getCarousel(line.id, index, e)
                                vm.lastMarker = marker.id
                            });                           
                            if (marker.category==1) {
                                //publicaties
                                el.className = 'publicaties';
                                el.style.backgroundImage = 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIUlEQVQ4T2N8K6Pyn4FMwASlyQKjmkkEo5pJBENSMwMDAPdQAkpCB3VDAAAAAElFTkSuQmCC")';
                                el.style.width = '15px'; 
                                el.style.height = '15px'; 
                            } else if (marker.category==2) {
                                //gebeurtenis
                                el.className = 'gebeurtenis';
                                el.style.backgroundImage = `url("data:image/png;base64,${marker.pointthumb}")`;
                                el.style.backgroundSize = 'contain';
                                el.style.backgroundRepeat = 'no-repeat';
                                el.style.backgroundPosition = 'center';
                                // el.style.backgroundSize = '60px 60px';
                                el.style.width = '60px'; //marker.properties.iconSize[0] + 'px';
                                el.style.height = '60px'; //marker.properties.iconSize[1] + 'px';
                            } else {
                                //publiek
                                el.className = 'publiek';
                                el.style.backgroundImage = 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAALtJREFUSInN1rEVwjAMRdEPXiB9ChXZgdKTpWQyl2zhETIBVARyLMuSI3N4vf5tBfxrMdIaI63Wu2BFgOcNwEw0LTlvyR36Qt6ZMBXEIGasCQmICRMhBaLGqpABUWEs1IE0sQI6gYjYAXJAqtgOOSIsFgYhBXYdMM4WACDnLRFNC4DZd/7ySCnfd2gM9kEOkC92RArIBysRFjqH8UgV6sPqiAjZMBlpQjqsjaggGdMhaojH9EhXve/Wz3oBn7KW/yvia4IAAAAASUVORK5CYII=")';
                                el.style.width = '26px';
                                el.style.height = '26px';
                            }
                
                            // add marker to map
                            var m = new mapboxgl.Marker({draggable: false, element: el});
                                m.setLngLat(JSON.parse(marker.coordinates))
                                if (marker.title) m.setPopup(new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(
                                    `<div style="background-color: #67a178; height: 200px; width: 130px;"><div><img ` +
                                    `src="data:image/png;base64,${marker.recordthumb}" style="height: 100px; width: 130px; object-fit: cover;"` +
                                    `/></div><div style="position: absolute; background-color: #ef5e33; padding: 4px;left: 10px; top: 85px;">${marker.date}</div><div style="padding-left: 10px;"><h4 style="color: white">${marker.title}<br /><a href="/tjaliepoint/${line.id}/${marker.id}" target="_self">lees meer</a></h4></div></div>`))
                                m.addTo(vm.map);
                            vm.markers[marker.id] = m
                        })
                        vm.map.addSource(line.title+rnd, {
                            'type':'geojson', 
                            'data': {
                                'type': 'FeatureCollection',
                                'features': [{
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'LineString',
                                        'properties': {},                   
                                        'coordinates': routeSourcePoints
                                    }
                                }]
                            }
                        })
                        vm.map.on('idle', function () {
                            if (!line.layered) {
                                line.layered = true
                                vm.map.addLayer({
                                    'id': line.title+rnd,
                                    'source': line.title+rnd,
                                    'layout': {
                                        'visibility': 'none'
                                    },
                                    'type': 'line',
                                    'visibility': 'none',
                                    'paint': {
                                        'line-width': 2,
                                        'line-color': '#' + line.color ?? '#007cbf',
                                        'line-dasharray': [2,Number(line.style)] //line.style === 'dash' ? [2,3] : [2,0]
                                    }
                                })
                            }
                        })
                        //vm.map.on('load', function () {
                        //     // vm.map.addSource(line.title+rnd, {
                        //     //     'type':'geojson', 
                        //     //     'data': {
                        //     //         'type': 'FeatureCollection',
                        //     //         'features': [{
                        //     //             'type': 'Feature',
                        //     //             'geometry': {
                        //     //                 'type': 'LineString',
                        //     //                 'properties': {},                   
                        //     //                 'coordinates': routeSourcePoints
                        //     //             }
                        //     //         }]
                        //     //     }
                        //     // })
                             //setTimeout(() => {
                        //         vm.map.addLayer({
                        //             'id': line.title+rnd,
                        //             'source': line.title+rnd,
                        //             'layout': {
                        //                 'visibility': 'none'
                        //             },
                        //             'type': 'line',
                        //             'visibility': 'none',
                        //             'paint': {
                        //                 'line-width': 2,
                        //                 'line-color': '#' + line.color ?? '#007cbf',
                        //                 'line-dasharray': [2,Number(line.style)] //line.style === 'dash' ? [2,3] : [2,0]
                        //             }
                        //         })
                        //     },2000)
                        //})
                    })
                })
            })
            vm.map.on('load', function () {
                //mapbox doesn't support ducth !!
                //vm.map.setLayoutProperty('country-label', 'text-field', ['get', 'name_nl'])
        //         vm.map.getStyle().layers.forEach(function(thisLayer){
        // console.log(thisLayer);
        //     if(thisLayer.id.indexOf('-label')>0){
        //         console.log('change '+thisLayer.id);
        //         vm.map.setLayoutProperty(thisLayer.id, 'text-field', ['get','name_es'])
        //     }
        // });               
         if (vm.$route.params && vm.$route.params.line && vm.$route.params.point) {
                    setTimeout(() => { 
                                    vm.getCarousel(vm.$route.params.line, 1, {cancelable: false})
                                    vm.toggleMarker(vm.$route.params.point)
                                }, 1000)
                            }
                        })
            vm.map.on('moveend', function () {
                window.localStorage.setItem('P_CENTER', JSON.stringify(vm.map.getCenter()))
                window.localStorage.setItem('P_ZOOM', vm.map.getZoom())
            })
        },
    }
};
class HelloWorldControl {
    constructor(vm) {
        this.vm = vm
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.id = 'addLifeToggle'
        this._container.className = 'mapboxgl-ctrl-geocoder mapboxgl-ctrl addlife';
        this._container.textContent = 'Maak een nieuwe levenslijn';
        const _this = this
        this._container.addEventListener('click', function(e){
            _this.vm.newLifeLine = {title: null, author: null, color: '1976D2', id: -1, lifePoints: [], style: 0},
            _this.vm.$refs.addLifePanel.toggle(e);
        })
        return this._container;
    }
    
    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
</script>

<style>
.basemap {
  width: 100%;
  height: 100vh;
}
.gebeurtenis {
  display: block;
  border:#67A178 5px solid;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}
.publiek {
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
}
.publicaties {
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mapboxgl-popup-tip {
 border-top-color: #67A178 !important;
}
.mapboxgl-popup-content {
    background: initial !important; 
    padding: initial !important;
}
.timeline {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 200;
    width: 100%;
    height: 150px;
    background-color: rgba(103, 161, 120, 0.2);
}
.timeline-item {
    max-width: 250px;
    border: 1px solid #67A178;
    padding: 5px;
    height: 150px;
    color: black;
}
.addlife {
    padding: 8px;
    color: grey;
    top: 50px;
}
.p-inputtext {
    width: 100%;
}
.p-colorpicker-preview {
    height: 2px;
}
.small-row {
    height: 25px;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;    
}
.pointPanel {
    width: 580px; 
    height: 800px;
    top: 106px !important;
    left: 80px !important;
}
.displayNone {
    display: none;
}
.lifePanel {
    width: 580px; 
    height: 800px;
    left: 80px !important; 
}

</style>